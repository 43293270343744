import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import { PATHS } from 'consts/paths';
import { MODAL_TYPES, SIGN_UP_MODAL_TYPES } from 'consts/modals';
import { QUERY_PARAMS } from 'consts/queryParams';
import { REF_SOURCES } from 'consts/referralSources';
import { AUTHENTICATE_TYPE } from 'consts/authenticateModal';
import { ReferralSourceContext } from 'contexts/ReferralSourceContext';
import useQueryParams from 'hooks/useQueryParams';
import { bulkPushBack, popFront, reset, pushFront, pushBackResetPasswordNewModal } from 'store/modals/actions';
import { getFrontModalData, getFrontModalType } from 'store/modals/selectors';
import { showSearchTooltipSelector } from 'store/dashboardInterface/selectors';
import * as userSelectors from 'store/user/selectors';
import Alooma from 'services/alooma';
import useAloomaSource from 'hooks/useAloomaSource';
import useAction from 'hooks/useAction';
import useActionWithDelay from 'hooks/useActionWithDelay';
import ModalBonusWelcome from 'components/organisms/ModalBonusWelcome/ModalBonusWelcome';
import ModalCompleteSignUp from 'components/organisms/ModalCompleteSignUp/ModalCompleteSignUp';
import ModalCongratsGuest from 'components/organisms/ModalCongratsGuest/ModalCongratsGuest';
import ModalCongratsUser from 'components/organisms/ModalCongratsUser/ModalCongratsUser';
import ModalListCreate from 'components/organisms/ModalListCreate/ModalListCreate';
import ModalListDelete from 'components/organisms/ModalListDelete/ModalListDelete';
import ModalListEdit from 'components/organisms/ModalListEdit/ModalListEdit';
import ModalListShare from 'components/organisms/ModalListShare/ModalListShare';
import ModalPaypalConfirm from 'components/organisms/ModalPaypalConfirm/ModalPaypalConfirm';
import ModalPaypalConnect from 'components/organisms/ModalPaypalConnect/ModalPaypalConnect';
import ModalPaypalDisconnect from 'components/organisms/ModalPaypalDisconnect/ModalPaypalDisconnect';
// import ModalResetPassword from 'components/organisms/ModalResetPassword/ModalResetPassword';
import ModalSuggestInstallExtension from 'components/organisms/ModalSuggestInstallExtension/ModalSuggestInstallExtension';
import ModalSuggestInstallRemovedExtension from 'components/organisms/ModalSuggestInstallRemovedExtension/ModalSuggestInstallRemovedExtension';
import ModalTagCreate from 'components/organisms/ModalTagCreate/ModalTagCreate';
import ModalTagsAddToList from 'components/organisms/ModalTagsAddToList/ModalTagsAddToList';
import ModalTagsDelete from 'components/organisms/ModalTagsDelete/ModalTagsDelete';
import ModalTagsFulfill from 'components/organisms/ModalTagsFulfill/ModalTagsFulfill';
import ModalTagsMute from 'components/organisms/ModalTagsMute/ModalTagsMute';
import ModalUnderMaintenance from 'components/organisms/ModalUnderMaintenance/ModalUnderMaintenance';
import ModalVideoHowToSave from 'components/organisms/ModalVideoHowToSave/ModalVideoHowToSave';
import ModalWithdrawCongrats from 'components/organisms/ModalWithdrawCongrats/ModalWithdrawCongrats';
import ModalDoNotSellMyInfo from 'components/organisms/ModalDoNotSellMyInfo/ModalDoNotSellMyInfo';
import ModalChangeLanguage from 'components/organisms/ModalChangeLanguage/ModalChangeLanguage';
import ModalProduct from 'components/organisms/ModalProduct/ModalProduct';
import ModalPartnershipsContact from 'components/molecules/ModalPartnershipsContact/ModalPartnershipsContact';
import ModalItemSaved from 'components/organisms/ModalItemSaved/ModalItemSaved';
import ModalCongratulateCompleteSignUp from 'components/organisms/ModalСongratulateCompleteSignUp/ModalСongratulateCompleteSignUp';
import ModalOnboarding from 'components/organisms/ModalOnboarding/ModalOnboarding';
import ModalAuthenticate from '../ModalAuthenticate/ModalAuthenticate';
import ModalChatWithUs from '../ModalChatWithUs/ModalChatWithUs';
import ModalResetPasswordNew from '../ModalResetPasswordNew/ModalResetPasswordNew';
import ModalStoreInfo from '../ModalStoreInfo/ModalStoreInfo';
import ModalManageStores from '../ModalManageStores/ModalManageStores';
import ModalDeleteAccount from '../ModalDeleteAccount/ModalDeleteAccount';
import {
  BootstrapModal,
  MixinModalBonusWelcome,
  MixinModalOutdatedOnboarding,
  MixinModalPaypal,
  MixinModalVideoHowToSave,
  MixinModalWithdraw,
  MixinModalDoNotSellMyInfo,
  MixinModalChangeLanguage,
  MixinModalProduct,
  MixinModalPartnershipsContact,
  MixinModalMyItems,
  MixinModalUnderMaintenance,
  MixinModalItemSaved,
  MixinModalAuthenticate,
  MixinModalResetPassword,
  MixinModalAuthenticateSmall,
  MixinModalChatWithUs,
  MixinModalStoreInfo,
  MixinModalManageStores,
  MixinModalDeleteAccount,
  MixinModalOnboarding,
} from './ModalsPortal.style';

const mapModalTypeToModal = {
  [MODAL_TYPES.partnershipContact]: ModalPartnershipsContact,
  [MODAL_TYPES.bonusWelcome]: ModalBonusWelcome,
  [MODAL_TYPES.completeSignUp]: ModalCompleteSignUp,
  [MODAL_TYPES.congratsGuest]: ModalCongratsGuest,
  [MODAL_TYPES.congratsUser]: ModalCongratsUser,
  [MODAL_TYPES.listCreate]: ModalListCreate,
  [MODAL_TYPES.listDelete]: ModalListDelete,
  [MODAL_TYPES.listEdit]: ModalListEdit,
  [MODAL_TYPES.listShare]: ModalListShare,
  [MODAL_TYPES.logIn]: ModalAuthenticate,
  [MODAL_TYPES.paypalConfirm]: ModalPaypalConfirm,
  [MODAL_TYPES.paypalConnect]: ModalPaypalConnect,
  [MODAL_TYPES.paypalDisconnect]: ModalPaypalDisconnect,
  // [MODAL_TYPES.resetPassword]: ModalResetPassword,
  [MODAL_TYPES.resetPasswordNew]: ModalResetPasswordNew,
  [MODAL_TYPES.signUp]: ModalAuthenticate,
  [MODAL_TYPES.authenticate]: ModalAuthenticate,
  [MODAL_TYPES.suggestInstallExtension]: ModalSuggestInstallExtension,
  [MODAL_TYPES.suggestInstallRemovedExtension]: ModalSuggestInstallRemovedExtension,
  [MODAL_TYPES.tagCreate]: ModalTagCreate,
  [MODAL_TYPES.tagsAddToList]: ModalTagsAddToList,
  [MODAL_TYPES.tagsDelete]: ModalTagsDelete,
  [MODAL_TYPES.tagsFulfill]: ModalTagsFulfill,
  [MODAL_TYPES.tagsMute]: ModalTagsMute,
  [MODAL_TYPES.underMaintenance]: ModalUnderMaintenance,
  [MODAL_TYPES.videoHowToSave]: ModalVideoHowToSave,
  [MODAL_TYPES.withdrawCongrats]: ModalWithdrawCongrats,
  [MODAL_TYPES.doNotSellMyInfo]: ModalDoNotSellMyInfo,
  [MODAL_TYPES.changeLanguage]: ModalChangeLanguage,
  [MODAL_TYPES.product]: ModalProduct,
  [MODAL_TYPES.itemSaved]: ModalItemSaved,
  [MODAL_TYPES.congratulateCompleteSignUp]: ModalCongratulateCompleteSignUp,
  [MODAL_TYPES.chatWithUs]: ModalChatWithUs,
  [MODAL_TYPES.storeInfo]: ModalStoreInfo,
  [MODAL_TYPES.manageStores]: ModalManageStores,
  [MODAL_TYPES.deleteAccount]: ModalDeleteAccount,
  [MODAL_TYPES.onboarding]: ModalOnboarding,
};

const mapModalTypeToBootstrapModalProps = {
  /**
   * My items modals
   */
  [MODAL_TYPES.listShare]: {
    as: MixinModalMyItems,
  },
  [MODAL_TYPES.listDelete]: {
    as: MixinModalMyItems,
  },
  [MODAL_TYPES.listCreate]: {
    as: MixinModalMyItems,
  },
  [MODAL_TYPES.listEdit]: {
    as: MixinModalMyItems,
  },
  [MODAL_TYPES.tagCreate]: {
    as: MixinModalMyItems,
  },
  [MODAL_TYPES.tagsDelete]: {
    as: MixinModalMyItems,
  },
  [MODAL_TYPES.tagsFulfill]: {
    as: MixinModalMyItems,
  },
  [MODAL_TYPES.tagsMute]: {
    as: MixinModalMyItems,
  },
  [MODAL_TYPES.tagsAddToList]: {
    as: MixinModalMyItems,
  },
  [MODAL_TYPES.product]: {
    as: MixinModalProduct,
  },

  /**
   * Onboarding modals
   */
  [MODAL_TYPES.signUp]: {
    as: MixinModalAuthenticate,
  },
  [MODAL_TYPES.completeSignUp]: {
    as: MixinModalAuthenticate,
  },
  [MODAL_TYPES.congratulateCompleteSignUp]: {
    as: MixinModalAuthenticate,
  },
  [MODAL_TYPES.logIn]: {
    as: MixinModalAuthenticate,
  },
  [MODAL_TYPES.authenticate]: {
    as: MixinModalAuthenticate,
  },

  /**
   * SEO modals
   */

  [MODAL_TYPES.itemSaved]: {
    as: MixinModalItemSaved,
  },

  /**
   * Other modals
   */
  [MODAL_TYPES.underMaintenance]: {
    as: MixinModalUnderMaintenance,
  },
  [MODAL_TYPES.partnershipContact]: {
    as: MixinModalPartnershipsContact,
  },
  [MODAL_TYPES.bonusWelcome]: {
    as: MixinModalBonusWelcome,
  },
  [MODAL_TYPES.congratsGuest]: {
    as: MixinModalOutdatedOnboarding,
  },
  [MODAL_TYPES.congratsUser]: {
    as: MixinModalOutdatedOnboarding,
  },
  [MODAL_TYPES.paypalConfirm]: {
    as: MixinModalPaypal,
  },
  [MODAL_TYPES.paypalConnect]: {
    as: MixinModalPaypal,
  },
  [MODAL_TYPES.paypalDisconnect]: {
    as: MixinModalPaypal,
  },
  // [MODAL_TYPES.resetPassword]: {
  //   backdrop: 'static',
  // },
  [MODAL_TYPES.resetPasswordNew]: {
    as: MixinModalResetPassword,
  },
  [MODAL_TYPES.suggestInstallExtension]: {
    as: MixinModalOutdatedOnboarding,
  },
  [MODAL_TYPES.suggestInstallRemovedExtension]: {
    as: MixinModalOutdatedOnboarding,
  },

  [MODAL_TYPES.videoHowToSave]: {
    as: MixinModalVideoHowToSave,
  },
  [MODAL_TYPES.withdrawCongrats]: {
    as: MixinModalWithdraw,
  },
  [MODAL_TYPES.doNotSellMyInfo]: {
    as: MixinModalDoNotSellMyInfo,
  },
  [MODAL_TYPES.changeLanguage]: {
    as: MixinModalChangeLanguage,
  },
  [MODAL_TYPES.chatWithUs]: {
    as: MixinModalChatWithUs,
  },
  [MODAL_TYPES.storeInfo]: {
    as: MixinModalStoreInfo,
  },
  [MODAL_TYPES.manageStores]: {
    as: MixinModalManageStores,
  },
  [MODAL_TYPES.deleteAccount]: {
    as: MixinModalDeleteAccount,
  },
  [MODAL_TYPES.onboarding]: {
    as: MixinModalOnboarding,
  },
};

const checkPath = (currentPath, routePath) => {
  return currentPath.match(pathToRegexp(routePath));
};

const getSourceFromPath = pathname => {
  if (
    checkPath(pathname, PATHS.COUPONS) ||
    checkPath(pathname, PATHS.COUPONS__NEWEST) ||
    checkPath(pathname, PATHS.COUPONS__POPULAR)
  ) {
    return REF_SOURCES.coupons;
  }

  if (checkPath(pathname, PATHS.ITEMS)) {
    return REF_SOURCES.dashboard;
  }

  if (checkPath(pathname, PATHS.RETAILERS)) {
    return REF_SOURCES.cashbackPicks;
  }

  if (checkPath(pathname, PATHS.HOME)) {
    return REF_SOURCES.home;
  }

  if (checkPath(pathname, PATHS.HOME) || checkPath(pathname, PATHS.DASHBOARD)) {
    return REF_SOURCES.home;
  }

  if (checkPath(pathname, PATHS.SHARE)) {
    return REF_SOURCES.wishlist;
  }

  if (checkPath(pathname, PATHS.SHOP)) {
    return REF_SOURCES.shop;
  }

  if (checkPath(pathname, PATHS.SEO_TAG)) {
    return REF_SOURCES.seo;
  }

  if (checkPath(pathname, PATHS.STORES)) {
    return REF_SOURCES.stores;
  }

  if (
    checkPath(pathname, PATHS.PROFILE) ||
    checkPath(pathname, PATHS.PROFILE_WALLET) ||
    checkPath(pathname, PATHS.PROFILE_ACCOUNT_SETTINGS)
  ) {
    return REF_SOURCES.profile;
  }

  if (checkPath(pathname, PATHS.KARMA_CASH)) {
    return REF_SOURCES.cashbackPicks;
  }

  if (checkPath(pathname, PATHS.STORES__FEATURED)) {
    return REF_SOURCES.featured;
  }

  if (checkPath(pathname, PATHS.STORES__WE_ARE_LOVING)) {
    return REF_SOURCES.stores;
  }

  if (checkPath(pathname, PATHS.STORES__TRENDING)) {
    return REF_SOURCES.trending;
  }

  if (checkPath(pathname, PATHS.KARMA_CASH_SINGLE) || checkPath(pathname, PATHS.RETAILER)) {
    return REF_SOURCES.retailer;
  }

  if (checkPath(pathname, PATHS.GLOBAL_SEARCH)) {
    return REF_SOURCES.globalSearch;
  }

  return undefined;
};

const porterSupportedModalTypesForAll = [MODAL_TYPES.videoHowToSave, MODAL_TYPES.changeLanguage];

const porterSupportedModalTypesForNotLoggedIn = [MODAL_TYPES.completeSignUp, MODAL_TYPES.logIn];

const porterSupportedForAuthorizedUserModalTypes = [MODAL_TYPES.paypalConfirm, MODAL_TYPES.paypalConnect];

const useModalsPorter = () => {
  const bulkPushBackFunc = useAction(bulkPushBack);
  const pushBackResetPasswordModalFunc = useAction(pushBackResetPasswordNewModal);
  const pushFrontFunc = useAction(pushFront);
  const isUserLoaded = useSelector(userSelectors.getLoaded);
  const isRegisteredUser = useSelector(userSelectors.getIsRegisteredUser);

  const {
    [QUERY_PARAMS.modals]: modalsQuery,
    [QUERY_PARAMS.resetPasswordToken]: resetPasswordTokenQuery,
    [QUERY_PARAMS.modalName]: modalNameQuery,
    [QUERY_PARAMS.modalData]: modalDataQuery,
  } = useQueryParams();

  const modals = useMemo(
    () =>
      modalsQuery
        ?.split(',')
        .filter(
          type =>
            (!isRegisteredUser && porterSupportedModalTypesForNotLoggedIn.includes(type)) ||
            porterSupportedModalTypesForAll.includes(type),
        )
        .map(type => ({
          type,
          data:
            MODAL_TYPES[type] === MODAL_TYPES.logIn
              ? {
                  authType: AUTHENTICATE_TYPE.LOGIN,
                }
              : undefined,
        })),
    [modalsQuery, isRegisteredUser],
  );

  const modalsForAuthorizedUser = useMemo(
    () =>
      modalsQuery
        ?.split(',')
        .filter(type => porterSupportedForAuthorizedUserModalTypes.includes(type))
        .map(type => ({ type })),
    [modalsQuery],
  );

  useEffect(() => {
    const onLoad = () => {
      bulkPushBackFunc(modals);
    };

    if (modals?.length) {
      if (document.readyState === 'complete') {
        bulkPushBackFunc(modals);
      } else {
        window.addEventListener('load', onLoad);
      }
    }

    return () => {
      window.removeEventListener('load', onLoad);
    };
  }, [bulkPushBackFunc, modals]);

  useEffect(() => {
    if (isUserLoaded) {
      if (modalsForAuthorizedUser?.length) {
        bulkPushBackFunc(modalsForAuthorizedUser);
      }
    }
  }, [bulkPushBackFunc, modalsForAuthorizedUser, isUserLoaded]);

  useEffect(() => {
    /**
     * case when page url contains "modalName" and "modalData" (optional) params
     * - modalName -- MODAL_TYPES property
     * - modalData -- data for modal
     */
    if (modalNameQuery && MODAL_TYPES[modalNameQuery] && isUserLoaded) {
      let data = (modalDataQuery || '').split(';').reduce((result, dataQuery) => {
        const [key, value] = dataQuery.split(':');

        if (!key || !value) return result;

        return {
          ...result,
          [key]: value,
        };
      }, {});

      if (MODAL_TYPES[modalNameQuery] === MODAL_TYPES.logIn) {
        data = {
          ...data,
          authType: AUTHENTICATE_TYPE.LOGIN,
        };
      }

      if (MODAL_TYPES[modalNameQuery] === MODAL_TYPES.signUp) {
        data = {
          ...data,
          authType: AUTHENTICATE_TYPE.SIGN_UP,
        };
      }

      if (SIGN_UP_MODAL_TYPES.includes(MODAL_TYPES[modalNameQuery]) && isRegisteredUser) return;

      pushFrontFunc({ type: MODAL_TYPES[modalNameQuery], data });
    }
  }, [isUserLoaded]);

  useEffect(() => {
    if (resetPasswordTokenQuery) {
      pushBackResetPasswordModalFunc({ resetPasswordToken: resetPasswordTokenQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const ModalsPortal = () => {
  const { pathname } = useLocation();

  const modalData = useSelector(getFrontModalData);
  const modalType = useSelector(getFrontModalType);
  const showSearchTooltip = useSelector(showSearchTooltipSelector);

  const refSource = getSourceFromPath(pathname);
  const aloomaSource = useAloomaSource();

  const resetFunc = useAction(reset);
  const closeFunc = useActionWithDelay(popFront);

  const Modal = mapModalTypeToModal[modalType];

  const bootstrapModalProps = useMemo(() => {
    if (SIGN_UP_MODAL_TYPES.includes(modalType)) {
      return checkPath(pathname, PATHS.WEBSITE_LANDING_PATH)
        ? {
            as: MixinModalAuthenticateSmall,
          }
        : {
            as: MixinModalAuthenticate,
          };
    }

    return mapModalTypeToBootstrapModalProps[modalType] ?? {};
  }, [modalType, pathname]);

  const closeModal = () => {
    closeFunc();

    setTimeout(() => {
      window.scrollTo({
        left: 0,
        behavior: 'instant',
      });
    }, 0);
  };

  useEffect(() => {
    resetFunc();
  }, [pathname, resetFunc]);

  useModalsPorter();

  if (!Modal || showSearchTooltip) {
    return null;
  }

  return (
    <ReferralSourceContext.Provider value={refSource}>
      <Alooma.SourceContext.Provider value={aloomaSource}>
        <BootstrapModal key={modalType} {...bootstrapModalProps} show onHide={closeModal}>
          <Modal closeModal={closeModal} {...modalData} sourcePage={aloomaSource} />
        </BootstrapModal>
      </Alooma.SourceContext.Provider>
    </ReferralSourceContext.Provider>
  );
};

export default ModalsPortal;
