import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@karma/components';
import { HidePasswordIcon, ShowPasswordIcon } from '@karma/components/icons';
import { oldApiInstance } from 'functions/request';
import ROUTES from 'consts/routes';
import { PATHS } from 'consts/paths';
import { authenticateUser } from 'functions/authentication/authentication';
import { handleServerError, showSuccessAlert } from 'store/alerts/actions';
import useAction from 'hooks/useAction';
import { isMobile } from 'functions/isMobile';
import InputFormField from '../InputFormField/InputFormField';
import SignUpWrapperNew from '../SignUpWrapperNew/SignUpWrapperNew';
import { Container, IconWrapper, StyledButton } from './ModalBodyResetPasswordNew.style';

const ModalBodyResetPasswordNew = ({ resetPasswordToken }) => {
  const { t } = useTranslation();

  const schema = yup.object({
    password: yup
      .string()
      .test(
        'password',
        t('app:passwordValidationNote'),
        value => value?.length >= 8 && /[0-9]/g.test(value) && /[a-z]/g.test(value) && /[A-Z]/g.test(value),
      )
      .required(t('app:enterYourPassword')),
    'repeat-password': yup
      .string()
      .test(
        'repeat-password',
        t('app:passwordValidationNote'),
        value => value?.length >= 8 && /[0-9]/g.test(value) && /[a-z]/g.test(value) && /[A-Z]/g.test(value),
      )
      .required(t('app:enterYourPassword'))
      .oneOf([yup.ref('password')], 'Passwords must match'),
  });

  const [isFetching, setIsFetching] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleServerErrorFunc = useAction(handleServerError);
  const showSuccessAlertFunc = useAction(showSuccessAlert);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      password: '',
      'repeat-password': '',
    },
    resolver: yupResolver(schema),
  });

  const resetPassword = useCallback(
    ({ password, 'repeat-password': repeatPassword }) => {
      setIsFetching(true);

      oldApiInstance
        .put(ROUTES.api.resetPasswordPath, {
          reset_password_token: resetPasswordToken,
          password,
          password_confirmation: repeatPassword,
        })
        .then(({ data }) => {
          if (isMobile()) {
            showSuccessAlertFunc({ message: t('app:passwordWasSuccessfullyReset') });

            return;
          }

          authenticateUser(data);
          window.location.href = PATHS.DASHBOARD;
        })
        .catch(error => {
          handleServerErrorFunc(error?.response);
          setIsFetching(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetPasswordToken, t],
  );

  const defaultProps = {
    control,
    disabled: isFetching,
    fullWidth: true,
  };

  const handleSubmitFunc = handleSubmit(resetPassword);

  return (
    <SignUpWrapperNew showImage={!isMobile()}>
      <Container direction="column" fullWidth spacing={24}>
        <Typography variant="h3">{t('app:createNewPassword')}</Typography>
        <form onSubmit={handleSubmitFunc} style={{ width: '100%' }}>
          <Stack direction="column" spacing={16} fullWidth>
            <InputFormField
              id="password"
              name="password"
              label={t('app:password')}
              placeholder={t('app:password')}
              type={showPassword ? 'text' : 'password'}
              AfterInput={
                <>
                  <IconWrapper role="button" onClick={() => setShowPassword(prev => !prev)}>
                    {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                  </IconWrapper>
                </>
              }
              {...defaultProps}
            />
            <InputFormField
              id="repeat-password"
              name="repeat-password"
              label="Repeat Password"
              placeholder="Password confirmation"
              type={showConfirmPassword ? 'text' : 'password'}
              AfterInput={
                <>
                  <IconWrapper role="button" onClick={() => setShowConfirmPassword(prev => !prev)}>
                    {showConfirmPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                  </IconWrapper>
                </>
              }
              {...defaultProps}
            />
            <StyledButton size="small" fullWidth onClick={handleSubmitFunc} loading={isFetching}>
              {t('app:resetMyPassword')}
            </StyledButton>
          </Stack>
        </form>
      </Container>
    </SignUpWrapperNew>
  );
};

ModalBodyResetPasswordNew.propTypes = {
  resetPasswordToken: PropTypes.string.isRequired,
};

export default ModalBodyResetPasswordNew;
